/* global.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body,
html,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
li,
td,
th,
input,
button,
textarea,
select {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  html {
    font-size: 75%;
  }
}

#root {
  display: flex;
  justify-content: center;
  min-height: 80%;
  height: auto;
}
